<template>
	<v-text-field
				  ref="inputComp"
				  v-model="timeStr"
				  :label="label"
				  dense outlined
				  hint="Left/Right arrows to move sections. Up/Down arrows change time by 1. Shift + up/down increment by 10"
				  :readonly="readonly"
				  :disabled="disabled">
	</v-text-field>
</template>


<script>
	export default {
		name: "time-input",

		props: {
			value: [String, Number],

			disabled: Boolean,

			readonly: Boolean,

			label: "",

			outputFormat: {
				type: String,
				default: "string",
				validator: function (value) {
					return ["string", "number"].indexOf(value) !== -1;
				},
			},
		},

		data() {
			return {
				timeStr: this.parseValue(this.value),
				regionSelected: false,
				regionCursorLocation: 0,
				focusRegistered: true,
			};
		},

		mounted() {
			this.input = this.$refs.inputComp;
			if (!$(this.input).is("input")) {
				this.input = $(this.input.$el).find("input")[0];
			}

			this.input.addEventListener("focus", this.onFocus);
			this.input.addEventListener("mousedown", this.onMousedown);
			this.input.addEventListener("mouseup", this.onMouseup);
			this.input.addEventListener("mouseleave", this.onMouseleave);
			this.input.addEventListener("blur", this.onBlur);
			this.input.addEventListener("keydown", this.onKeydown);
		},

		methods: {
			parseValue(val) {
				let timeStr = "";

				if (typeof val == "string" && val.match(/^\d{2,}:\d{2}:\d{2}/)) {
					timeStr = val;
				} else if ($.isNumeric(val)) {
					timeStr = this.$insight.timers.formatDuration(val);
				} else {
					timeStr = "00:00:00";
				}

				return timeStr;
			},

			onFocus(e) {
				if (!this.focusRegistered) {
					return;
				}

				this.$nextTick(() => {
					this.setRegion(this.getCurrentRegion());
				});
			},

			onMousedown(e) {
				this.focusRegistered = false;
			},

			onMouseup(e) {
				this.focusRegistered = true;

				this.$nextTick(() => {
					this.setRegion(this.getCurrentRegion());
				});
			},

			onMouseleave(e) {
				this.focusRegistered = true;
			},

			onBlur() {
				this.regionSelected = false;
			},

			onKeydown(e) {
				if (e.key != "Tab") {
					e.preventDefault();
				}

				let diff, parts;

				switch (e.key) {
					case "ArrowLeft":
					case "ArrowRight":
					case ":":
						diff = e.key == "ArrowLeft" ? -1 : 1;
						this.changeRegionBy(diff);
						break;

					case "ArrowUp":
					case "ArrowDown":
						diff = e.key == "ArrowDown" ? -1 : 1;
						parts = this.timeStr.split(":");

						if (e.shiftKey) {
							diff *= 10;
						} else if (e.altKey) {
							diff *= 5;
						}

						diff *= Math.pow(60, 2 - this.regionSelected);

						let time = this.$insight.timers.getDurationFromString(
							this.timeStr
						);
						time = Math.min(Math.max(time + diff, 0), 24 * 60 * 60 - 1);

						this.timeStr = this.$insight.timers.formatDuration(time);
						this.$nextTick(() => {
							this.setRegion();
						});
						break;

					case "0":
					case "1":
					case "2":
					case "3":
					case "4":
					case "5":
					case "6":
					case "7":
					case "8":
					case "9":
						parts = this.timeStr.split(":");
						let newPart =
							parts[this.regionSelected].substr(
								this.regionCursorLocation,
								1
							) + e.key;

						if (this.regionSelected == 0) {
							if (parseInt(newPart) > 23) {
								newPart = 23;
							}
						} else {
							if (parseInt(newPart) > 59) {
								newPart = 59;
							}
						}

						parts[this.regionSelected] = newPart;
						this.timeStr = parts.join(":");

						this.regionCursorLocation++;
						if (this.regionCursorLocation == 2) {
							this.regionCursorLocation = 0;
						}

						this.$nextTick(() => {
							this.setRegion();
						});
						break;

					case "Delete":
						parts = this.timeStr.split(":");
						parts[this.regionSelected] = "00";
						this.timeStr = parts.join(":");
						this.regionCursorLocation = 0;
						this.$nextTick(() => {
							this.setRegion();
						});
						break;

					case "Tab":
						if (
							(e.shiftKey && this.regionSelected > 0) ||
							(!e.shiftKey && this.regionSelected < 2)
						) {
							e.preventDefault();
							this.changeRegionBy(e.shiftKey ? -1 : 1);
						}
						break;
				}
			},

			changeRegionBy(diff) {
				let newRegion = Math.min(
					2,
					Math.max(0, this.regionSelected + diff)
				);
				this.setRegion(newRegion);
			},

			getCurrentRegion() {
				let parts = this.timeStr.split(":"),
					pos = this.input.selectionStart,
					tmp = 0;

				for (let i = 0; i < parts.length; i++) {
					let rightBound = tmp + parts[i].length;
					if (i < parts.length - 1) {
						rightBound++;
					}

					if (pos >= tmp && pos < rightBound) {
						return i;
					}

					tmp += parts[i].length + 1;
				}

				return parts.length - 1;
			},

			setRegion(region) {
				if (typeof region == "undefined") {
					region = this.regionSelected;
				}

				if (this.regionSelected != region) {
					this.regionCursorLocation = 0;
				}

				this.regionSelected = region;
				if (region === false) {
					return;
				}

				let parts = this.timeStr.split(":"),
					start = parts.slice(0, region).reduce((len, part) => {
						return len + part.length + 1;
					}, 0);

				this.input.selectionStart = start;
				this.input.selectionEnd = start + parts[region].length;
			},
		},

		watch: {
			value(to) {
				let timeStr = this.parseValue(to);
				if (timeStr != this.timeStr) {
					this.timeStr = timeStr;
				}
			},

			timeStr(to) {
				if (this.outputFormat == "number") {
					this.$emit(
						"input",
						this.$insight.timers.getDurationFromString(to)
					);
				} else {
					this.$emit("input", to);
				}
			},
		},
	};
</script>