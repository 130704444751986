<template>
	<v-dialog :value="true" min-width="300" max-width="400" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Edit Timer</v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="error" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>
				<v-menu v-if="this.$store.getters.isAdminUser" ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="date" :disabled="saving" type="date" label="Edit Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details dense outlined class="my-3"></v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable color="primary" @input="menu = false"></v-date-picker>
				</v-menu>
				<timer-input v-model="duration" label="Edit Time" :disabled="saving" />
			</v-card-text>

			<v-card-actions>
				<v-btn :disabled="saving" @click="modal.trigger('close')" class="ml-auto">Close</v-btn>
				<v-btn color="primary" @click="updateTimer" :loading="saving">Save</v-btn>
			</v-card-actions>

			<!-- <pre>{{timer}}</pre> -->
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";

	import timerInput from "../timeInput.vue";

	export default {
		name: "timerDurationEdit",

		components: {
			timerInput,
		},

		props: {
			modal: {
				type: Object,
				required: true,
			},
			timer: {
				type: Object,
				default: null,
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				saving: false,

				duration: this.getTimerDuration(),
				originalDate: moment(this.timer.creation_date).format("YYYY-MM-DD"),
				date: moment(this.timer.creation_date).format("YYYY-MM-DD"),
				menu: false,
			};
		},

		methods: {
			getTimerDuration() {
				let duration = this.$insight.timers.getDuration(this.timer);
				return this.$insight.timers.formatDuration(duration);
			},

			updateTimer() {
				if (!moment.duration(this.duration).isValid()) {
					this.modalErrorMessage = "Please enter a valid duration";
				}

				this.saving = true;
				let data = { duration: this.duration };
				if (this.date != this.originalDate) {
					data.creation_date = this.date;
				}

				this.$xhrRequest
					.send("put", `/api/timer/${this.timer.id}`, data)
					.then(
						(timer) => {
							this.$db.addModels("timer", timer);
							this.modal.trigger("save close", timer);
						},
						(xhr) => {
							this.saving = false;
							this.$snotify.error( "Error!", "Cannot update timer duration" );
						}
					);



					/* this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateTimerArgs!) {
									updateTimer(data: $data) {
										id
										time_spent
										creation_date @skip this.date != this.originalDate
									}
								}
							`,
							variables: {
								data: {
									id: timer.id,
									creation_date: this.date
								}
							},
							update: (store, { data: { updateTimer } }) => {
								this.saving = false;
								this.modal.trigger("save close", timer);
							},
						})
						.catch((error) => {
							this.saving = false;
							console.error("error updating timer", error);
							this.modalErrorMessage = "There was a problem updating the timer.";
						}); */
			},
		},
	};
</script>